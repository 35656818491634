<template>
    <Template
        v-bind="{
            ...params,
            isLoading,
        }"
        @onSubmit="() => onRegister()"
        @goTo="(path) => $router.push(path)"
    />
</template>
<script>
import Template from "../components/templates/RegistrationView/SplitScreen/SplitScreen";
import { c } from "@/components/constants.js";

export default {
    components: {
        Template,
    },
    methods: {
        async onRegister() {
            if (this.isLoading) return false;
            this.params.inputs.email.error = null;
            this.params.inputs.password.error = null;
            this.params.inputs.firstName.error = null;
            this.params.inputs.lastName.error = null;
            const forms = this.params.inputs;
            console.log(forms, "forms ====");
            if (!forms.email?.value) {
                this.params.inputs.email.error = "Please insert email!";
                return false;
            }
            if (!forms.password?.value) {
                this.params.inputs.password.error = "Please insert password!";
                return false;
            }
            if (!forms.firstName?.value) {
                this.params.inputs.firstName.error =
                    "Please insert first name!";
                return false;
            }
            if (!forms.lastName?.value) {
                this.params.inputs.lastName.error = "Please insert last name!";
                return false;
            }

            try {
                this.isLoading = true;
                if (this.$route.query?.email && this.$route.query?.userId) {
                    await this.actions.user.saveUser({
                        where: { id: this.$route.query?.userId },
                        data: {
                            email: forms.email?.value,
                        },
                    });
                }
                const user = await this.actions.createAppUser({
                    email: forms.email?.value,
                    password: forms.password?.value,
                    sendEmailVerification: true,
                    userType: this.$route.query?.type || "founder",
                });

                console.log(user, "user");

                if (!user.isNewUser) {
                    this.$router.push({
                        name: "Login",
                        query: this.$route.query,
                    });
                    return false;
                }

                if (user?.user?.id) {
                    // await this.actions.login({ email: forms.email?.value, password: forms.password?.value, isRegister: true });
                    const firstName = forms?.firstName?.value;
                    const lastName = forms?.lastName?.value;
                    const fullName =
                        firstName + (lastName ? ` ${lastName}` : "");

                    const userData = {
                        firstName,
                        firstNameLower: firstName?.toLowerCase(),
                        lastName,
                        lastNameLower: lastName?.toLowerCase(),
                        fullName,
                        fullNameLower: fullName?.toLowerCase(),
                    };

                    // update the user
                    await this.actions.user.saveUser({
                        where: { id: user?.user?.id },
                        data: userData,
                    });

                    // if COMPANY is set in the query params
                    if (this.$route.query?.c) {
                        await this.actions.user.saveUser({
                            where: { id: this.state.currentUser?.id },
                            data: {
                                company: {
                                    connect: { id: this.$route.query?.c },
                                },
                            },
                        });
                    }

                    console.log("calling invite user action...");

                    await this.actions.user.inviteUserAction({
                        route: this.$route,
                        user: user?.user,
                    });

                    // if GROUP is set in the query params
                    if (this.$route.query?.g) {
                        await this.actions.user.saveUser({
                            where: { id: this.state.currentUser?.id },
                            data: {
                                groups: {
                                    connect: [{ id: this.$route.query?.g }],
                                },
                            },
                        });
                    }

                    // if (this.state.isLoggedIn) {
                    this.actions.alert.showSuccess({
                        message: "Registered successfully!",
                    });

                    this.$router.push({
                        name: "Verify Email Step",
                        query: {
                            email: forms.email?.value,
                            type: "founder",
                            userId: user?.user?.id,
                        },
                    });
                    // }
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
    data() {
        return {
            params: c.register,
            isLoading: false,
            isAgreed: false,
        };
    },

    async mounted() {
        const email = this.$route.fullPath
            ?.split("?")?.[1]
            ?.split("&")
            ?.find((e) => e?.includes("e="))
            ?.split("=")?.[1];

        console.log(email, "email");

        if (email && !this.$route.fullPath?.includes("type=founder")) {
            const { findAllUsersByNumberOrEmail } =
                await this.actions.user.findAllUsersByNumberOrEmail({
                    keys: [email],
                });
            if (findAllUsersByNumberOrEmail?.length > 0) {
                await this.actions.user.inviteUserAction({
                    route: this.$route,
                    user: findAllUsersByNumberOrEmail?.[0],
                });
                this.$router.push("/login");
            }
            this.params.inputs.email.value = email;
        }
    },
};
</script>
